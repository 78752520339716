import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { formatTimestampLong } from "utils/format";

import styles from "./style.module.scss";
import { useFeature } from "components/OrganizationFeatures";
import { useRoutes } from "utils/routes";

type LogLineType = {
  occurredAt: number;
  databaseId?: string;
  logLevel: string;
  backendPid: number;
  postgresRoleId: string;
  postgresRoleName: string;
  logClassification: string | null | undefined;
};

type Props = {
  content: string;
  logLine: LogLineType;
  serverId: string;
  databaseId?: string;
};

const LogLineContent: React.FunctionComponent<Props> = (props) => {
  const { content, logLine, serverId } = props;
  const {
    occurredAt,
    logLevel,
    backendPid,
    postgresRoleName,
    postgresRoleId,
    logClassification,
  } = props.logLine;
  const { serverRole, serverLogs, databaseLogs, databaseBackendAtTime } =
    useRoutes();
  const hasActivitySnapshotsFeature = useFeature("activitySnapshots");

  // For server-wide log lines fallback to the database ID we're on
  // (right now Log Insights always displays on a specific database)
  const databaseId = logLine.databaseId || props.databaseId;

  const classificationPath = databaseId
    ? databaseLogs(databaseId, logClassification)
    : serverLogs(serverId, logClassification);

  return (
    <span className={styles.logLineContent}>
      {formatTimestampLong(moment(occurredAt * 1000))}
      &nbsp;
      {logClassification && (
        <span>
          <Link
            className={styles.logLineClassification}
            to={classificationPath}
          >
            {logClassification}
          </Link>
          &nbsp;
        </span>
      )}
      {(!hasActivitySnapshotsFeature || !databaseId) && (
        <span className={styles.logLineBackendPid}>{backendPid}</span>
      )}
      {hasActivitySnapshotsFeature && databaseId && (
        <Link
          className={styles.logLineBackendPid}
          to={databaseBackendAtTime(databaseId, backendPid, occurredAt)}
        >
          {backendPid}
        </Link>
      )}
      &nbsp;
      {postgresRoleName && (
        <span>
          <Link
            className={styles.logLineRole}
            to={serverRole(serverId, postgresRoleId)}
          >
            {postgresRoleName}
          </Link>
          &nbsp;
        </span>
      )}
      {content.startsWith(logLevel) ? content : `${logLevel}: ${content}`}
    </span>
  );
};

export default LogLineContent;
