import PageContent from "components/PageContent";
import Panel from "components/Panel";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import UPDATE_MUTATION from "./Mutation.update.graphql";
import WORKBOOK_DETAIL_QUERY from "../Query.graphql";
import UNSELECT_MUTATION from "../ExplainWorkbookRunExplain/Mutation.unselect.graphql";
import Grid from "components/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandableSQL from "components/ExpandableSQL";
import {
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../types/ExplainWorkbookDetails";
import {
  convertParamValue,
  JsonParametersType,
  renderKeyValueForParam,
} from "../util";
import { ExplainWorkbookHeader } from "..";
import Callout from "components/Callout";
import {
  faCheckCircle,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import {
  UpdateExplainParameterSets,
  UpdateExplainParameterSetsVariables,
} from "./types/UpdateExplainParameterSets";
import {
  UpdateExplainParameterSetsSelected,
  UpdateExplainParameterSetsSelectedVariables,
} from "../ExplainWorkbookRunExplain/types/UpdateExplainParameterSetsSelected";
import { useParams } from "react-router-dom";
import { ParameterSetsSidebar } from "../ExplainWorkbookParameterSets";

const ExplainWorkbookParameterSetsEdit = ({
  workbook,
  explainQuery,
  featureNav,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
  featureNav: React.ReactNode;
}) => {
  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");

  const [updateExplainWorkbook] = useMutation<
    UpdateExplainParameterSetsSelected,
    UpdateExplainParameterSetsSelectedVariables
  >(UNSELECT_MUTATION, {
    refetchQueries: [
      {
        query: WORKBOOK_DETAIL_QUERY,
        variables: { workbookId: workbook.id, databaseId },
      },
    ],
  });

  const expectedAliasesLength = workbook.parameterRefAliases.length;
  // For the case the workbook was initially created without params but new param set is added
  const newParamNotFilled =
    workbook.parameterSets.length === 0 && expectedAliasesLength > 0;
  const allValuesSet =
    !newParamNotFilled &&
    workbook.parameterSets.every(
      (pset) => pset.paramValues.length === expectedAliasesLength,
    );

  const handleRunExplain = () => {
    updateExplainWorkbook({
      variables: {
        workbookId: workbook.id,
        parameterSetsSelected: true,
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  // TODO: show component to allow creating a new param set when newParamNotFilled
  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      featureInfo={<ExplainWorkbookHeader workbook={workbook} />}
      pageCategory="explains"
      pageName="workbooks"
      layout="sidebar"
      featureNav={featureNav}
    >
      {/* main content */}
      {!allValuesSet && (
        <Callout
          title="Parameter Sets need an update"
          variant="warning"
          className="mb-4"
        >
          New parameters are added with this new variant. Please update
          parameter set values.
        </Callout>
      )}
      <div className="mb-4 leading-6">
        <ExpandableSQL sql={explainQuery.queryTextWithAlias} />
      </div>
      <Panel title="Existing Parameter Sets">
        <Grid
          className="grid-cols-1fr"
          data={workbook.aliasParamMapList}
          defaultSortBy="id"
          columns={[
            {
              field: "id",
              header: "Parameter Set",
              renderer: function ParametersCell({ rowData, fieldData }) {
                return (
                  <ParameterSetCell
                    setId={fieldData}
                    parameterSet={rowData.parameters}
                    workbook={workbook}
                    setErrorMessage={setErrorMessage}
                  />
                );
              },
              className: "whitespace-normal",
              disableSort: true,
            },
          ]}
        />
      </Panel>
      {errorMessage && <div className="text-[#FF0000]">{errorMessage}</div>}
      <div className="grid">
        <div className="justify-self-end">
          <button
            className="btn btn-success !px-4"
            disabled={!allValuesSet}
            onClick={handleRunExplain}
          >
            Run EXPLAIN...
          </button>
        </div>
      </div>
      {/* sidebar */}
      <ParameterSetsSidebar />
    </PageContent>
  );
};

const ParameterSetCell = ({
  setId,
  parameterSet,
  workbook,
  setErrorMessage,
}: {
  setId: string;
  parameterSet: JsonParametersType;
  workbook: ExplainWorkbookType;
  setErrorMessage: (message: string) => void;
}) => {
  const [newParamValues, setNewParamValues] = useState<{
    [key: string]: string;
  }>({});

  const [updateExplainParameterSets] = useMutation<
    UpdateExplainParameterSets,
    UpdateExplainParameterSetsVariables
  >(UPDATE_MUTATION);

  const existingParam = workbook.parameterSets.find((val) => val.id === setId);
  const expectedAliases = workbook.parameterRefAliases;

  const missingAliases = expectedAliases.slice(
    existingParam.paramValues.length,
  );

  const handleParamValuesChange = (alias: string, value: string) => {
    const newSet = { ...newParamValues };
    if (value === "") {
      // remove from the set when the value becomes an empty string
      delete newSet[alias];
      setNewParamValues(newSet);
      return;
    }
    newSet[alias] = convertParamValue(value);
    setNewParamValues(newSet);
  };

  const handleUpdateParameters = () => {
    if (Object.keys(newParamValues).length !== missingAliases.length) {
      setErrorMessage(
        "Please enter values for all parameters missing values. For a NULL value, use null; for an empty string, use empty_str instead of leaving the field blank.",
      );
      return;
    }
    updateExplainParameterSets({
      variables: {
        workbookId: workbook.id,
        parameterSetId: setId,
        parameters: newParamValues,
      },
      onCompleted: () => {
        setErrorMessage("");
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const inputFields: React.JSX.Element[] = [];
  missingAliases.forEach((alias) => {
    inputFields.push(
      <div key={`label-${alias}`} className="font-medium">
        ${alias}
      </div>,
    );
    inputFields.push(
      <input
        key={`input-${alias}`}
        className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5"
        type="text"
        onChange={(e) => {
          handleParamValuesChange(alias, e.target.value);
        }}
      />,
    );
  });
  return (
    <div>
      <div className="grid grid-cols-[1fr_40px] items-center">
        <div className="flex gap-2 items-center">
          {missingAliases.length === 0 ? (
            <FontAwesomeIcon icon={faCheckCircle} />
          ) : (
            <FontAwesomeIcon icon={faTriangleExclamation} />
          )}
          <pre className="border-none m-0 p-0 bg-none bg-transparent whitespace-pre-wrap">
            {Object.entries(parameterSet).map(([key, value], i, arr) => {
              const missing = missingAliases.includes(key.slice(1));
              const missingClass = missing ? "text-[#A16006]" : "";
              return (
                <React.Fragment key={key}>
                  <span className={missingClass}>
                    {missing ? key : renderKeyValueForParam(key, value)}
                  </span>
                  {i + 1 < arr.length && ", "}
                </React.Fragment>
              );
            })}
          </pre>
        </div>
      </div>
      {missingAliases.length > 0 && (
        <div>
          <div className="grid grid-cols-[min-content_1fr_min-content_1fr] gap-2 mb-2 mt-2 items-center">
            {Object.entries(inputFields).map(([_, elem]) => elem)}
          </div>
          <div>
            <button
              className="btn btn-success"
              onClick={handleUpdateParameters}
            >
              Update parameters
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExplainWorkbookParameterSetsEdit;
