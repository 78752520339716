import React, { useState } from "react";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import {
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../types/ExplainWorkbookDetails";
import PanelSection from "components/PanelSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import Button from "components/Button";
import UNSELECT_MUTATION from "./Mutation.unselect.graphql";
import CREATE_MUTATION from "./Mutation.create.graphql";
import WORKBOOK_DETAIL_QUERY from "../Query.graphql";
import { useMutation } from "@apollo/client";
import {
  UpdateExplainParameterSetsSelected,
  UpdateExplainParameterSetsSelectedVariables,
} from "./types/UpdateExplainParameterSetsSelected";
import CopyToClipboard from "components/CopyToClipboard";
import SQL from "components/SQL";
import { jsonParametersToString } from "../util";
import {
  CreateExplainResult,
  CreateExplainResultVariables,
} from "./types/CreateExplainResult";
import { useNavigate, useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";
import { ExplainWorkbookHeader } from "..";
import Grid from "components/Grid";
import ExpandableSQL from "components/ExpandableSQL";

type RunMethodType = "manual" | "collector" | "undefined";

const ExplainWorkbookRunExplain = ({
  workbook,
  explainQuery,
  featureNav,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
  featureNav?: React.ReactNode;
}) => {
  const [runMethod, setRunMethod] = useState<RunMethodType>("undefined");

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      featureInfo={<ExplainWorkbookHeader workbook={workbook} />}
      pageCategory="explains"
      pageName="workbooks"
      layout="sidebar"
      featureNav={featureNav}
    >
      <ExplainWorkbookRunExplainContent
        runMethod={runMethod}
        setRunMethod={setRunMethod}
        workbook={workbook}
        explainQuery={explainQuery}
      />
      {runMethod === "manual" ? (
        <RunExplainManualSidebar />
      ) : (
        <div className="w-[320px]" />
      )}
    </PageContent>
  );
};

const ExplainWorkbookRunExplainContent = ({
  workbook,
  runMethod,
  setRunMethod,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  runMethod: RunMethodType;
  setRunMethod: React.Dispatch<RunMethodType>;
  explainQuery: ExplainQueryType;
}) => {
  switch (runMethod) {
    case "manual":
      return (
        <ExplainWorkbookRunExplainManual
          workbook={workbook}
          setRunMethod={setRunMethod}
          explainQuery={explainQuery}
        />
      );
    case "collector":
      return (
        <ExplainWorkbookRunExplainCollector
          workbook={workbook}
          setRunMethod={setRunMethod}
          explainQuery={explainQuery}
        />
      );
    case "undefined":
      return (
        <ExplainWorkbookRunExplainSelection
          workbook={workbook}
          setRunMethod={setRunMethod}
          explainQuery={explainQuery}
        />
      );
  }
};

const ExplainWorkbookRunExplainManual = ({
  workbook,
  setRunMethod,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  setRunMethod: React.Dispatch<RunMethodType>;
  explainQuery: ExplainQueryType;
}) => {
  const navigate = useNavigate();
  const [explainResult, setExplainResult] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { databaseWorkbookVariant } = useRoutes();

  // Set the result list length as an initial state in case a user leaves the page in the middle of adding results
  // (e.g. if 1 result is already there out of 3, currentParamSet becomes 1 and will start with the second param set)
  const [currentParamSet, setCurrentParamSet] = useState(
    explainQuery.explainResults.length,
  );

  const [createExplainResult] = useMutation<
    CreateExplainResult,
    CreateExplainResultVariables
  >(CREATE_MUTATION);

  // TODO: disable switching to the collector for param set 2+?
  // or maybe only run with collector for remaining param sets
  const secondaryTitle = (
    <div>
      <span className="font-semibold">Manually</span> |{" "}
      <Button bare onClick={() => setRunMethod("collector")}>
        With collector
      </Button>
    </div>
  );
  const paramSetsCount = workbook.parameterSets.length;
  const showNextButton =
    paramSetsCount > 1 && paramSetsCount > currentParamSet + 1;
  // TODO: change command based on preference (add verbose? maybe checkbox to select?)
  // switch format too
  const explainCommand = "EXPLAIN (ANALYZE, VERBOSE, BUFFERS, FORMAT JSON)\n";
  const currentQuery =
    paramSetsCount === 0
      ? explainQuery.queryText
      : explainQuery.queryTextWithParameters[currentParamSet]
          .queryWithParameters;
  const explainQueryText = `${explainCommand}${currentQuery}${
    currentQuery.endsWith(";") ? "" : ";"
  }`;

  const handleSubmitCurrentSet = () => {
    if (explainResult === "") {
      setErrorMessage("Explain result is required");
      return;
    }
    let format = "json";
    try {
      JSON.parse(explainResult);
    } catch {
      format = "text";
    }
    const parameterSetId =
      explainQuery.queryTextWithParameters[currentParamSet]?.parameterSetId;
    createExplainResult({
      variables: {
        explainQueryId: explainQuery.id,
        explainJson: format === "json" ? explainResult : "",
        explainText: format === "text" ? explainResult : "",
        parameterSetId: parameterSetId,
      },
      onCompleted: () => {
        setErrorMessage("");
        setExplainResult("");
        setCurrentParamSet(currentParamSet + 1);
        if (!showNextButton) {
          navigate(
            databaseWorkbookVariant(
              workbook.databaseId,
              workbook.id,
              explainQuery.id,
            ),
          );
        }
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  return (
    <Panel title="Run EXPLAIN" secondaryTitle={secondaryTitle}>
      <PanelSection>
        <div className="grid grid-cols-2">
          <div className="mb-1 font-medium">
            Parameter set ({currentParamSet + 1} / {paramSetsCount || 1})
          </div>
          <div className="mb-1 justify-self-end text-[#606060]">
            <CopyToClipboard
              label="Copy EXPLAIN command to clipboard"
              content={explainQueryText}
            />
          </div>
        </div>
        <pre className="max-h-[200px] overflow-auto">
          <code>
            <SQL sql={explainQueryText} />
          </code>
        </pre>
        <div className="grid grid-cols-2 mt-4">
          <div className="mb-1 font-medium">EXPLAIN output</div>
          <div className="mb-1 justify-self-end text-[#606060]">
            Text or JSON format supported
          </div>
          <div className="col-span-2">
            <textarea
              rows={20}
              className="bg-white rounded border border-gray-300 box-border w-full leading-5 px-2 py-1.5 disabled:bg-[#eee]"
              onChange={(e) => setExplainResult(e.target.value)}
              value={explainResult}
              placeholder="Paste EXPLAIN output here..."
            />
          </div>
        </div>
        <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
        <div className="text-right mt-4">
          <button
            className="btn !px-10"
            onClick={() => {
              setRunMethod("undefined");
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-success !w-[140px]"
            onClick={handleSubmitCurrentSet}
          >
            {showNextButton ? "Next Set..." : "Submit"}
          </button>
        </div>
      </PanelSection>
    </Panel>
  );
};

const ExplainWorkbookRunExplainCollector = ({
  workbook,
  setRunMethod,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  setRunMethod: React.Dispatch<RunMethodType>;
  explainQuery: ExplainQueryType;
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const secondaryTitle = (
    <div>
      <Button bare onClick={() => setRunMethod("manual")}>
        Manually
      </Button>{" "}
      | <span className="font-semibold">With collector</span>
    </div>
  );
  const noParamSets = workbook.parameterSets.length === 0;

  return (
    <Panel title="Run EXPLAIN" secondaryTitle={secondaryTitle}>
      <PanelSection>
        <ExpandableSQL sql={explainQuery.queryTextWithAlias} />
        <div className="mt-4">
          Run EXPLAIN using the pganalyze collector
          {noParamSets ? "." : " with the following parameter sets:"}
        </div>
      </PanelSection>
      {!noParamSets && (
        <Grid
          className="grid-cols-1fr"
          data={workbook.aliasParamMapList}
          columns={[
            {
              field: "id",
              header: "Parameter Set",
              className: "whitespace-normal",
              renderer: function ParametersCell({ rowData }) {
                return (
                  <pre className="border-none m-0 p-0 bg-none bg-transparent whitespace-pre-wrap">
                    {jsonParametersToString(rowData.parameters, null)}
                  </pre>
                );
              },
              disableSort: true,
            },
          ]}
        />
      )}
      <PanelSection>
        <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
        <div className="text-right mt-4">
          <button
            className="btn !px-10"
            onClick={() => {
              setRunMethod("undefined");
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-success !w-[140px]"
            onClick={() => {
              setErrorMessage(
                "Running EXPLAIN with the collector is not supported yet",
              );
            }}
          >
            Run
          </button>
        </div>
      </PanelSection>
    </Panel>
  );
};

const ExplainWorkbookRunExplainSelection = ({
  workbook,
  setRunMethod,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  setRunMethod: React.Dispatch<RunMethodType>;
  explainQuery: ExplainQueryType;
}) => {
  const { databaseId } = useParams();
  const [updateExplainWorkbook] = useMutation<
    UpdateExplainParameterSetsSelected,
    UpdateExplainParameterSetsSelectedVariables
  >(UNSELECT_MUTATION, {
    refetchQueries: [
      {
        query: WORKBOOK_DETAIL_QUERY,
        variables: { workbookId: workbook.id, databaseId },
      },
    ],
  });
  const [errorMessage, setErrorMessage] = useState("");

  // Only allow with the initial workbook creation flow to avoid all edge cases
  const allowCancelParameterSetSelect =
    workbook.baselineQuery.id === explainQuery.id;

  return (
    <>
      <Panel title="Select How to Run EXPLAIN ANALYZE">
        <PanelSection>
          Create a baseline for this workbook that you can use to verify
          potential performance issues, as well as take further experimentation
          from there.
          <div className="grid grid-cols-2 mt-4 gap-3 h-[200px]">
            <div className="bg-[#eeeeff] text-[#4e2758] rounded-md p-4 grid content-between">
              <div>
                <div className="text-[22px] leading-none mb-1">
                  <FontAwesomeIcon icon={faCopy} />
                </div>
                <div className="text-[18px] font-medium">
                  Import plans manually
                </div>
                <div>
                  Run EXPLAIN ANALYZE yourself and upload the query plan output
                </div>
              </div>
              <div>
                <button
                  className="btn btn-success"
                  onClick={() => setRunMethod("manual")}
                >
                  Run Manually...
                </button>
              </div>
            </div>
            <div className="bg-[#fcefe0] text-[#584427] rounded-md p-4 grid content-between">
              <div>
                <div className="text-[22px] leading-none mb-1">
                  <FontAwesomeIcon icon={faSparkles} />
                </div>
                <div className="text-[18px] font-medium">
                  Automatically generate and import plans
                </div>
                <div>
                  Save time by using the collector to automatically execute
                  EXPLAIN ANALYZE queries for you.
                </div>
              </div>
              <div>
                <button
                  className="btn btn-success disabled"
                  onClick={() => setRunMethod("collector")}
                >
                  Run with Collector...
                </button>
              </div>
            </div>
          </div>
        </PanelSection>
      </Panel>
      {errorMessage && <div className="text-[#FF0000]">{errorMessage}</div>}
      <div>
        {allowCancelParameterSetSelect && (
          <div className="text-end">
            <Button
              bare
              onClick={() => {
                updateExplainWorkbook({
                  variables: {
                    workbookId: workbook.id,
                    parameterSetsSelected: false,
                  },
                  onError: (error) => {
                    setErrorMessage(error.message);
                  },
                });
              }}
              className="!px-5 !text-[#273858] !font-medium"
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const RunExplainManualSidebar = () => {
  return (
    <div className="w-[320px]">
      <h4 className="leading-7 mt-0">Best Practices</h4>
      <div className="mb-3">
        <span className="font-medium">Before running EXPLAIN command:</span>
        <div>
          The default psql formatting is not stable for copy and pasting the
          result. Update the psql settings to make it easy to copy and paste:
          <code>\pset tuples_only true \pset format unaligned</code>
          <CopyToClipboard
            content={"\\pset tuples_only true \\pset format unaligned"}
          />
        </div>
      </div>
      <div className="mb-3">
        <span className="font-medium">Copy the result to the clipboard:</span>{" "}
        In psql, run the following before running the EXPLAIN command. Note that
        you need to exit (<code>\q</code>) to actually copy the content to the
        clipboard.
        <pre>
          \t \a
          <br />
          \o |pbcopy -b
          <br />
          -- PASTE THE EXPLAIN COMMAND
          <br />
          \q
        </pre>
        The above <code>pbcopy</code> command is for macOS. For other systems,
        similar tools are available like <code>xsel</code> or <code>xclip</code>
        . Alternatively, save the EXPLAIN query into a file named explain.sql,
        then run the following command to copy the result to the clipboard:
        <div>
          <code>psql -XqAt -f explain.sql | pbcopy</code>
        </div>
      </div>
      <div className="mb-3">
        <span className="font-medium">Run Multiple Times:</span> Execute EXPLAIN
        ANALYZE multiple times (typically 3-5) to account for caching effects
        and get a more accurate representation of query performance.
      </div>
      <div className="mb-3">
        <span className="font-medium">Warm Up the Cache:</span> Run the query a
        few times before using EXPLAIN ANALYZE to ensure that the data is loaded
        into memory, providing a more realistic view of performance.
      </div>
      <div className="mb-3">
        <span className="font-medium">Use Realistic Data:</span> Test with a
        dataset that closely resembles your production data in size and
        distribution to get meaningful insights.
      </div>
      <div>
        <span className="font-medium">Monitor Variability:</span> Observe the
        variance in execution times across runs to identify consistent
        performance issues versus anomalies.
      </div>
    </div>
  );
};

export default ExplainWorkbookRunExplain;
