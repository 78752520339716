import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import PageContent from "components/PageContent";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import PageIssueList from "components/PageIssueList";
import PostgresRoleList from "components/PostgresRoleList";
import ReplicationDetails from "components/ReplicationDetails";

import StorageAndIO from "./StorageAndIO";
import CPUAndLoadAvg from "./CPUAndLoadAvg";
import Memory from "./Memory";
import Network from "./Network";

import {
  SystemDetails as SystemDetailsType,
  SystemDetailsVariables,
  SystemDetails_getSystemDetails_info_data,
  SystemDetails_getSystemDetails_stats,
} from "./types/SystemDetails";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import DateRangeBar from "components/DateRangeBar";
import { useRoutes } from "utils/routes";
import Callout from "components/Callout";

type Props = {
  tab: string;
};

const SystemDetails: React.FunctionComponent<Props> = ({ tab }) => {
  const { serverId } = useParams();
  const { serverSystem, serverReplication, serverRoles } = useRoutes();
  const { data, loading, error } = useQuery<
    SystemDetailsType,
    SystemDetailsVariables
  >(QUERY, {
    variables: {
      serverId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const { info, stats } = data.getSystemDetails;
  const { systemType, amazonRdsEnhanced } = info || {};

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink to={serverSystem(serverId)}>Overview</PageNavLink>
      {supportsCPUMetrics(systemType) && (
        <PageNavLink to={serverSystem(serverId, "cpu")}>
          {getCPUMetricsLabel(systemType, amazonRdsEnhanced)}
        </PageNavLink>
      )}
      {supportsMemoryMetrics(systemType, amazonRdsEnhanced) && (
        <PageNavLink to={serverSystem(serverId, "memory")}>Memory</PageNavLink>
      )}
      {supportsIOStorageMetrics(systemType) && (
        <PageNavLink to={serverSystem(serverId, "storage")}>
          I/O &amp; Storage
        </PageNavLink>
      )}
      {supportsNetworkMetrics(systemType) && (
        <PageNavLink to={serverSystem(serverId, "network")}>
          Network
        </PageNavLink>
      )}
      <PageNavLink to={serverReplication(serverId)}>Replication</PageNavLink>
      <PageNavLink to={serverRoles(serverId)}>Postgres Roles</PageNavLink>
    </PageSecondaryNavigation>
  );

  if (!info || (info.systemType != "heroku" && !stats)) {
    return (
      <PageContent
        title="System"
        pageControls={<DateRangeBar />}
        featureNav={featureNav}
        pageCategory="server"
        pageName="system"
        pageProps={{ hasData: false }}
      >
        <Panel title="Server Info">
          <PanelSection>
            <p>
              <strong>Error:</strong> No server data received.
            </p>
            <p>
              This might be because you are monitoring a remote system for which
              we can't get disk I/O and other server statistics.
            </p>
          </PanelSection>
        </Panel>
      </PageContent>
    );
  }

  // These are for Amazon RDS
  const {
    status,
    region,
    instanceId,
    instanceClass,
    storageType,
    storageProvisionedIops,
    availabilityZone,
    publiclyAccessible,
    multiAz,
    secondaryAvailabilityZone,
    backupRetentionPeriod,
    parameterApplyStatus,
    autoMinorVersionUpgrade,
    preferredMaintenanceWindow,
    preferredBackupWindow,
    latestRestorableTime,
    isAuroraPostgres,
  } = info.data || ({} as SystemDetails_getSystemDetails_info_data);

  // These are for Physical systems
  const { operatingSystem, virtualizationSystem, platform, platformVersion } =
    info.data || ({} as SystemDetails_getSystemDetails_info_data);
  const {
    cpuHardwareModel,
    cpuHardwareSockets,
    cpuHardwareCoresPerSocket,
    storageMountpoint,
  } = stats || ({} as SystemDetails_getSystemDetails_stats);

  // These are for Crunchy Bridge
  const {
    clusterName,
    planId,
    providerId,
    regionId,
    cpuUnits,
    storageGb,
    memoryGb,
  } = info.data || ({} as SystemDetails_getSystemDetails_info_data);
  const crunchyBridgeClusterId = info.crunchyBridgeClusterId;

  return (
    <PageContent
      title="System"
      pageControls={<DateRangeBar />}
      featureNav={featureNav}
      pageCategory="server"
      pageName="system"
      pageTab={tab}
      pageProps={{ hasData: true }}
    >
      <PageIssueList
        serverId={serverId}
        referentId={serverId}
        referentType="Server"
      />
      {tab == "overview" && (
        <Panel title="Server Info">
          {systemType == "amazon_rds" && (
            <div>
              <div className={styles.infoContainer}>
                <div className={styles.infoColumn}>
                  <h3 className={styles.infoHeading}>Instance Details</h3>
                  <PanelTable horizontal={true} borders={false}>
                    <tbody>
                      <InfoCell name="Status" value={status || "Unknown"} />
                      <InfoCell
                        name="Instance ID"
                        value={instanceId || "Unknown"}
                      />
                      <InfoCell
                        name="Instance Class"
                        value={instanceClass || "Unknown"}
                      />
                      <InfoCell
                        name="Storage Type"
                        value={storageType || "Unknown"}
                      />
                      {storageProvisionedIops && (
                        <InfoCell
                          name="Provisioned IOPS"
                          value={storageProvisionedIops}
                        />
                      )}
                      <InfoCell
                        name="Enhanced Monitoring"
                        value={amazonRdsEnhanced ? "Enabled" : "Disabled"}
                      />
                    </tbody>
                  </PanelTable>
                </div>
                <div className={styles.infoColumn}>
                  <h3 className={styles.infoHeading}>Availability</h3>
                  <PanelTable horizontal={true} borders={false}>
                    <tbody>
                      <InfoCell
                        name="Availability Zone"
                        value={availabilityZone || "Unknown"}
                      />
                      <InfoCell
                        name="Publicly Accessible"
                        value={publiclyAccessible ? "Yes" : "No"}
                      />
                      <InfoCell
                        name="Multi AZ"
                        value={multiAz ? "Yes" : "No"}
                      />
                      <InfoCell
                        name="2nd Availability Zone"
                        value={secondaryAvailabilityZone || "n/a"}
                      />
                      <InfoCell
                        name="Automated Backups"
                        value={
                          (backupRetentionPeriod &&
                            `Enabled (${backupRetentionPeriod} Days)`) ||
                          "Disabled"
                        }
                      />
                    </tbody>
                  </PanelTable>
                </div>
                <div className={styles.infoColumn}>
                  <h3 className={styles.infoHeading}>Maintenance Details</h3>
                  <PanelTable horizontal={true} borders={false}>
                    <tbody>
                      <InfoCell
                        name="Parameter Group"
                        value={parameterApplyStatus || "Unknown"}
                      />
                      <InfoCell
                        name="Auto Minor Version Upgrade"
                        value={autoMinorVersionUpgrade ? "Yes" : "No"}
                      />
                      <InfoCell
                        name="Maintenance Window"
                        value={preferredMaintenanceWindow || "Unknown"}
                      />
                      <InfoCell
                        name="Backup Window"
                        value={preferredBackupWindow || "Unknown"}
                      />
                      <InfoCell
                        name="Latest Restorable Time"
                        value={latestRestorableTime || "Unknown"}
                      />
                    </tbody>
                  </PanelTable>
                </div>
              </div>
              {region && instanceId && (
                <PanelSection>
                  <a
                    href={`https://console.aws.amazon.com/rds/home?region=${region}#dbinstance:id=${instanceId}`}
                    className="btn-amazon"
                  >
                    View in AWS Console
                  </a>
                </PanelSection>
              )}
            </div>
          )}
          {systemType == "physical" && (
            <div className={styles.infoContainer}>
              <div className={styles.infoColumn}>
                <h3 className={styles.infoHeading}>System</h3>
                <PanelTable horizontal={true} borders={false}>
                  <tbody>
                    <InfoCell
                      name="Operating System"
                      value={operatingSystem || "Unknown"}
                    />
                    <InfoCell
                      name="Inside VM/Container?"
                      value={
                        (virtualizationSystem &&
                          `Yes - ${virtualizationSystem}`) ||
                        "No"
                      }
                    />
                    <InfoCell
                      name="Distribution"
                      value={
                        (platform &&
                          platformVersion &&
                          `${platform} ${platformVersion}`) ||
                        "Unknown"
                      }
                    />
                  </tbody>
                </PanelTable>
              </div>
              <div className={styles.infoColumn}>
                <h3 className={styles.infoHeading}>CPU</h3>
                <PanelTable horizontal={true} borders={false}>
                  <tbody>
                    <InfoCell
                      name="Model"
                      value={cpuHardwareModel || "Unknown"}
                    />
                    <InfoCell
                      name="Sockets"
                      value={
                        (cpuHardwareSockets && `${cpuHardwareSockets}`) ||
                        "Unknown"
                      }
                    />
                    <InfoCell
                      name="Logical Cores"
                      value={
                        (cpuHardwareCoresPerSocket &&
                          `${cpuHardwareCoresPerSocket}`) ||
                        "Unknown"
                      }
                    />
                  </tbody>
                </PanelTable>
              </div>
            </div>
          )}
          {systemType == "heroku" && (
            <PanelSection>
              You are using <strong>Heroku Postgres</strong>.
            </PanelSection>
          )}
          {systemType == "crunchy_bridge" &&
            (clusterName ? (
              <div>
                <div className={styles.infoContainer}>
                  <div className={styles.infoColumn}>
                    <h3 className={styles.infoHeading}>Cluster Details</h3>
                    <PanelTable horizontal={true} borders={false}>
                      <tbody>
                        <InfoCell name="Cluster Name" value={clusterName} />
                        <InfoCell name="Plan" value={planId} />
                        <InfoCell name="Provider" value={providerId} />
                        <InfoCell name="Region" value={regionId} />
                      </tbody>
                    </PanelTable>
                  </div>
                  <div className={styles.infoColumn}>
                    <h3 className={styles.infoHeading}>Resources</h3>
                    <PanelTable horizontal={true} borders={false}>
                      <tbody>
                        <InfoCell name="Memory" value={`${memoryGb} GB`} />
                        <InfoCell name="CPU" value={`${cpuUnits} vCores`} />
                        <InfoCell name="Storage" value={`${storageGb} GB`} />
                      </tbody>
                    </PanelTable>
                  </div>
                </div>
                {crunchyBridgeClusterId && (
                  <PanelSection>
                    <a
                      href={`https://crunchybridge.com/clusters/${crunchyBridgeClusterId}`}
                      className="btn btn-primary"
                      target="_blank"
                      rel="noopener"
                    >
                      View in Crunchy Bridge
                    </a>
                  </PanelSection>
                )}
              </div>
            ) : (
              <PanelSection>
                <p>
                  You are using <strong>Crunchy Bridge</strong>.
                </p>
                <Callout
                  variant="warning"
                  learnMoreLink="https://pganalyze.com/docs/install/crunchy_bridge/01_deploy_the_collector#create-crunchy-bridge-api-key"
                >
                  <code>CRUNCHY_BRIDGE_API_KEY</code> is not passed to the
                  collector. This causes inaccurate report of the Storage Space
                  metric. Set up the <code>CRUNCHY_BRIDGE_API_KEY</code> with
                  the collector version 0.52.3 and above to obtain accurate
                  Storage Space information, as well as cluster information.
                </Callout>
              </PanelSection>
            ))}
          {systemType == "aiven" && (
            <PanelSection>
              You are using <strong>Aiven for PostgreSQL</strong>.
            </PanelSection>
          )}
          {systemType == "google_cloudsql" && (
            <PanelSection>
              <p>
                You are using <strong>Google Cloud SQL</strong>.
              </p>
              <p>
                pganalyze currently does not provide integration with Cloud SQL
                system metrics.
              </p>
            </PanelSection>
          )}
          {systemType == "azure_database" && (
            <PanelSection>
              <p>
                You are using <strong>Azure Database for PostgreSQL</strong>.
              </p>
              <p>
                pganalyze currently does not provide integration with Azure
                Database for PostgreSQL system metrics.
              </p>
            </PanelSection>
          )}
          {systemType == "tembo" && (
            <PanelSection>
              You are using <strong>Tembo</strong>.
            </PanelSection>
          )}
        </Panel>
      )}
      {tab == "cpu" &&
        (supportsCPUMetrics(systemType) ? (
          <CPUAndLoadAvg
            serverId={serverId}
            amazonRdsEnhanced={amazonRdsEnhanced}
            systemType={systemType}
          />
        ) : (
          <SystemMetricsNotSupported kind="CPU" />
        ))}
      {tab == "memory" &&
        (supportsMemoryMetrics(systemType, amazonRdsEnhanced) ? (
          <Memory serverId={serverId} />
        ) : (
          <SystemMetricsNotSupported kind="Memory" />
        ))}
      {tab == "storage" &&
        (supportsIOStorageMetrics(systemType) ? (
          <StorageAndIO
            amazonRdsEnhanced={amazonRdsEnhanced}
            serverId={serverId}
            isAuroraPostgres={isAuroraPostgres}
            storageMountpoint={storageMountpoint}
            systemType={systemType}
          />
        ) : (
          <SystemMetricsNotSupported kind="Storage and I/O" />
        ))}
      {tab == "network" &&
        (supportsNetworkMetrics(systemType) ? (
          <Network serverId={serverId} />
        ) : (
          <SystemMetricsNotSupported kind="Network" />
        ))}
      {tab == "replication" && <ReplicationDetails serverId={serverId} />}
      {tab == "roles" && <PostgresRoleList serverId={serverId} />}
    </PageContent>
  );
};

const SystemMetricsNotSupported: React.FunctionComponent<{ kind: string }> = ({
  kind,
}) => {
  return (
    <Panel title="Not supported">
      <PanelSection>
        {kind} system metrics are not supported for your system.
      </PanelSection>
    </Panel>
  );
};

function supportsCPUMetrics(systemType: string): boolean {
  return [
    "physical",
    "crunchy_bridge",
    "amazon_rds",
    "heroku",
    "tembo",
  ].includes(systemType);
}

function getCPUMetricsLabel(
  systemType: string,
  amazonRdsEnhanced: boolean,
): string {
  if (systemType == "heroku") {
    return "Load Average";
  }
  if (
    ["physical", "crunchy_bridge"].includes(systemType) ||
    amazonRdsEnhanced
  ) {
    return "CPU & Load Average";
  }
  return "CPU";
}

function supportsMemoryMetrics(
  systemType: string,
  amazonRdsEnhanced: boolean,
): boolean {
  return (
    ["physical", "crunchy_bridge", "heroku", "tembo"].includes(systemType) ||
    amazonRdsEnhanced
  );
}

function supportsIOStorageMetrics(systemType: string): boolean {
  return [
    "physical",
    "crunchy_bridge",
    "amazon_rds",
    "heroku",
    "tembo",
  ].includes(systemType);
}

function supportsNetworkMetrics(systemType: string): boolean {
  return systemType == "amazon_rds";
}

const InfoCell: React.FunctionComponent<{ name: string; value: string }> = ({
  name,
  value,
}) => {
  return (
    <tr>
      <th>{name}</th>
      <td>{value}</td>
    </tr>
  );
};

export default SystemDetails;
