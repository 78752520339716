import React from "react";
import { useParams } from "react-router-dom";

import ExplainPanel from "components/ExplainPanel";
import type { ExplainPlanType, QuerySampleType } from "components/Explain/util";
import { useQuery } from "@apollo/client";
import QUERY from "./Query.graphql";
import { ExplainResult, ExplainResultVariables } from "./types/ExplainResult";
import {
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import Loading from "components/Loading";
import Popover from "components/Popover";
import { jsonParametersToString } from "../ExplainWorkbook/util";

const ExplainResultPanel = ({
  workbook,
  explainQuery,
  blockSize,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
  blockSize: number;
}) => {
  const { databaseId, variantId, resultId } = useParams();

  const { loading, error, data } = useQuery<
    ExplainResult,
    ExplainResultVariables
  >(QUERY, {
    variables: {
      explainQueryId: variantId,
      resultId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const explainResult = data.getExplainQueryResult;
  const usedAlias = Object.values(explainQuery.paramRefAliasMap) as string[];
  const parameters = workbook.aliasParamMapList.find(
    (v) => v.id === explainResult.parameterSetId,
  );
  const parameterSet = (
    <Popover
      content={jsonParametersToString(parameters.parameters, null, usedAlias)}
    >
      Used Parameter Set: Set {explainResult.parameterSetId}
    </Popover>
  );

  const explainPlan: ExplainPlanType = {
    id: explainResult.id,
    seenAt: explainResult.createdAt,
    querySample: {
      id: explainResult.id,
      occurredAt: explainResult.createdAt,
      runtimeMs: explainResult.runtimeMs,
      queryText: explainResult.queryText,
    } as QuerySampleType,
    query: { id: explainResult.id },
    format: explainResult.format,
    fingerprint: explainResult.planFingerprint,
    outputText: explainResult.outputText,
    annotatedJson: explainResult.annotatedJson,
    totalCost: explainResult.totalCost,
    totalSharedBlksRead: explainResult.totalSharedBlksRead,
    totalBlkReadTime: explainResult.totalBlkReadTime,
  };
  return (
    <ExplainPanel
      explain={explainPlan}
      databaseId={databaseId}
      blockSize={blockSize}
      parameterSet={parameterSet}
      hideComparison
    />
  );
};

export default ExplainResultPanel;
