import Panel from "components/Panel";
import React from "react";

import {
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import { formatMs } from "utils/format";
import Grid, { MsCell, NumberCell } from "components/Grid";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandableSQL from "components/ExpandableSQL";
import Identicon from "components/Identicon";
import Badge from "components/Badge";
import { Link, useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";
import { faMagnifyingGlassArrowRight } from "@fortawesome/pro-regular-svg-icons";

const ExplainVariant = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
}) => {
  const { databaseId } = useParams();
  const { databaseWorkbookVariantResult } = useRoutes();
  return (
    <>
      <ExplainQueryPanel explainQuery={explainQuery} />
      <Panel title="Query Plans">
        <Grid
          className="grid-cols-[35px_1fr_140px_repeat(3,minmax(5%,110px))]"
          data={explainQuery.explainResults}
          pageSize={5}
          defaultSortBy="parameterSetId"
          columns={[
            {
              field: "id",
              header: "",
              disableSort: true,
              renderer: ({ fieldData }) => (
                <Link
                  to={databaseWorkbookVariantResult(
                    databaseId,
                    workbook.id,
                    explainQuery.id,
                    fieldData,
                  )}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlassArrowRight} />
                </Link>
              ),
            },
            {
              field: "parameterSetId",
              header: "Parameter Set",
              nullValue: "-",
              disableSort: true,
              defaultSortOrder: "asc",
              renderer: ({ fieldData }) => `Param Set ${fieldData}`,
            },
            {
              field: "planFingerprint",
              header: "Plan",
              renderer: function PlanFingerprintCell({ fieldData }) {
                return (
                  <>
                    <Identicon identity={fieldData} />
                    <span title={fieldData}>{fieldData.substring(0, 7)}</span>
                  </>
                );
              },
            },
            {
              field: "totalCost",
              header: "Est. Cost",
              renderer: NumberCell,
              style: "number",
            },
            {
              field: "runtimeMs",
              header: "Runtime",
              renderer: MsCell,
              style: "number",
            },
            {
              field: "totalBlkReadTime",
              header: "I/O Read Time",
              renderer: MsCell,
              style: "number",
              nullValue: "-",
            },
          ]}
        />
      </Panel>
    </>
  );
};

const ExplainQueryPanel = ({
  explainQuery,
}: {
  explainQuery: ExplainQueryType;
}) => {
  // TODO: update badges based on the query
  const badges = (
    <Badge className="ml-0">
      <FontAwesomeIcon icon={faBell} /> NEW SLOW QUERY
    </Badge>
  );
  const runtimeValues = explainQuery.explainResults.map((val) => val.runtimeMs);
  return (
    <div className="rounded-md bg-[#f7fafc] grid grid-cols-2 p-4 mb-4 gap-2">
      <div className="col-span-2">{badges}</div>
      <div className="col-span-2 text-[18px] leading-6">
        {explainQuery.name}
      </div>
      <div className="col-span-2">
        <ExpandableSQL sql={explainQuery.queryTextWithAlias} />
      </div>
      <div>
        <div className="text-[11px]">MIN RUNTIME</div>
        <div className="text-[16px]">
          {formatMs(Math.min(...runtimeValues))}
        </div>
      </div>
      <div>
        <div className="text-[11px]">MAX RUNTIME</div>
        <div className="text-[16px]">
          {formatMs(Math.max(...runtimeValues))}
        </div>
      </div>
    </div>
  );
};

export default ExplainVariant;
